import React, { ReactNode } from 'react';

export interface IContent {
  OPTION: string;
  TEXT: ReactNode[];
  ON_CLICK: string[];
}

interface Constants {
  [key: string]: IContent;
}

export const MAIN_BRANCH = ['WHAT', 'WHY', 'WHERE', 'HOBBIES', 'CONTACT'];

export const CONSTANTS: Constants = {
  WHAT: {
    OPTION: 'What is this site?',
    TEXT: [
      'What is any personal website but a human shouting into the void hoping that their echo is picked up by another human being like them?',
      'Or that’s how it felt anyway, back when I first purchased my first web domain in the 90’s. I’ll admit, I miss when the internet was weird and horrifyingly unique and not all social media profiles or Squarespace templates. I thought I’d bring my little corner of the internet back to those times.',
      // @ts-ignore
      <marquee>Also I will defend the marquee tag until my dying breath.</marquee>,
    ],
    ON_CLICK: MAIN_BRANCH,
  },
  WHY: {
    OPTION: 'Why the sky? Why the sea?',
    TEXT: [
      'I’d love for there to be a deeper meaning to it, but it’s just a combination of two things that I love to look at and sometimes draw, have lived near for most of my life, and possess a healthy respect for (as with all things in nature, which won’t think twice about ending you). Also, I love alliteration.',
    ],
    ON_CLICK: MAIN_BRANCH,
  },
  WHERE: {
    OPTION: 'Where are you from?',
    TEXT: [
      'I was born in Singapore, transplanted to Chicago, and can currently be found as an invasive species in Seattle, Washington.',
    ],
    ON_CLICK: ['WHERE_1'],
  },
  WHERE_1: {
    OPTION: 'Why Seattle?',
    TEXT: [
      'Oh, you know, for the most foolish of reasons: love and video games. I picked up and moved for someone I met on World of Warcraft and somehow, against all odds, it worked out. We’ve been together over a decade since.',
      'After several years of effort, I managed to develop Stockholm Syndrome for Seattle’s 9 months of gray skies a year and 3 months of perfect summer weather. I tell myself it’s worth it but secretly long for warm year-round riding seasons.',
      'But hey, at least in Seattle you can get a cider in practically every bar.',
    ],
    ON_CLICK: MAIN_BRANCH,
  },
  HOBBIES: {
    OPTION: 'What else do you do?',
    TEXT: [
      <>
        I’m striving to become a modern-day climate-change-fighting hippy (
        <a href="https://www.reddit.com/r/ZeroWaste">/r/ZeroWaste</a> being my holy grail). I raise
        chickens in our backyard and I’m attempting to turn our front yard into a victory garden
        (primarily of potatoes because I could literally eat nothing but potatoes all day).
      </>,
      <>
        I enjoy volunteering: I’m currently a web admin for{' '}
        <a href="https://www.geekgirlcon.com">GeekGirlCon</a> (one of my absolute favorite
        conventions), previously served on the board of Seattle Indies and IGDA Seattle, and
        semi-frequently volunteer with United Way of King County (reach out if you ever want a
        volunteering buddy).
      </>,
    ],
    ON_CLICK: MAIN_BRANCH,
  },
  CONTACT: {
    OPTION:
      'How do I escape this prison of pre-defined options and actually contact you about something?',
    TEXT: [
      <>
        Sadly, I can only help you with the latter and not the former, but you can shoot me an email
        at <a href="mailto:hello@theskyandthesea.com">hello@theskyandthesea.com</a>.
      </>,
    ],
    ON_CLICK: MAIN_BRANCH,
  },
};

export const LATEST_CONTENT_ID = 'js-latest-content';
