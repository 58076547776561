import React, { useState } from 'react';

import { CONSTANTS, LATEST_CONTENT_ID, IContent } from './_constants';

import './_options.scss';

interface Props {
  options: string[];
  setOptions(options: string[]): void;
  addContent(content: IContent): void;
}

export const Options: React.FC<Props> = ({ options, setOptions, addContent }) => {
  const [animationCss, setAnimationCss] = useState('reveal reveal--down reveal--long-delay');

  const [readOptions, setReadOptions] = useState({} as { [key: string]: boolean });
  const addReadOption = (optionKey: string) =>
    setReadOptions({ ...readOptions, [optionKey]: true });

  return (
    <div className={animationCss}>
      {options.map((optionKey: string) => {
        // Don't show options that the visitor has already read
        if (readOptions[optionKey]) return null;

        const option = CONSTANTS[optionKey];
        if (!option) return null;
        const { OPTION, ON_CLICK } = option;

        return (
          <button
            type="button"
            className="option-text option-text--button"
            onClick={() => {
              addContent(option);
              addReadOption(optionKey);
              setOptions(ON_CLICK);
              setAnimationCss('');

              // Slight wait for new content to render in
              setTimeout(() => {
                setAnimationCss('reveal reveal--down reveal--long-delay');
                document.getElementById(LATEST_CONTENT_ID).scrollIntoView({ behavior: 'smooth' });
              }, 10);
            }}
            key={optionKey}
          >
            {OPTION}
          </button>
        );
      })}
    </div>
  );
};
