import React, { useEffect } from 'react';

import { LinkToPlants } from '../plants/_link';
import { LinkToTravel } from '../travel/_link';
import { LinkToArt } from '../art/_link';

import { LATEST_CONTENT_ID, IContent } from './_constants';

import './_content.scss';

interface Props {
  content: IContent[];
}

export const Content: React.FC<Props> = ({ content }) => {
  // Accessibility enhancement: focus on newly added content so screen readers read it out
  useEffect(() => {
    const newContent = document.getElementById(LATEST_CONTENT_ID);
    if (newContent) newContent.focus();
  }, [content.length]);

  return (
    <>
      <h1 className="intro-text reveal reveal--right">Hi. I’m Cee.</h1>
      <small className="intro-pronouns reveal reveal--right">(Pronouns: they/them)</small>

      <p className="intro-description reveal reveal--right">
        I have a lot of <LinkToPlants>houseplants</LinkToPlants>. I love being on{' '}
        <LinkToTravel>two wheels</LinkToTravel>. By day, I make websites. By night, I{' '}
        <LinkToArt>try to be creative</LinkToArt> and usually fail.
      </p>

      {content.map(({ OPTION, TEXT }, i) => {
        const isNewContent = i === content.length - 1;
        const id = isNewContent ? LATEST_CONTENT_ID : undefined;
        const tabIndex = isNewContent ? -1 : undefined;
        const classes = `reveal reveal--down ${isNewContent ? 'content-sr-focus' : ''}`;

        return (
          <div key={i} id={id} className={classes} tabIndex={tabIndex}>
            <h2 className="option-text">{OPTION}</h2>
            {TEXT.map((text, j) => (
              <p key={j}>{text}</p>
            ))}
          </div>
        );
      })}
    </>
  );
};
