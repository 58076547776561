import React, { useState } from 'react';

import { Head } from 'src/components/head/head';
import { Layout } from 'src/components/layout/layout';

import { Content } from './_content';
import { Options } from './_options';
import { IContent } from './_constants';

export const About: React.FC = () => {
  const [content, setContent] = useState([]);
  const addContent = (newContent: IContent) => setContent([...content, newContent]);

  const [options, setOptions] = useState(['WHAT']);

  return (
    <>
      <Head />
      <Layout className="intro">
        <Content content={content} />
        <Options options={options} setOptions={setOptions} addContent={addContent} />
      </Layout>
    </>
  );
};

export default About;
